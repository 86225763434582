import Pkg from '../../../package'
import React, { Component } from 'react'
import Cookies from 'js-cookie'
import styles from './Navbar.css'
import normalize from '../../assets/css/normalize.css'
import '../../assets/css/fonts.css'
import UserDropdown from '../user-dropdown/UserDropdown.js'
import EnvLabel from '../env-label/EnvLabel.js'
import isMatch from './match.js'
import ArcDropdown from '../arc-dropdown/ArcDropdown.js'
import ham from '../../assets/img/fa/bars.svg'
import { getLinkTranslation } from '../i18n/translate.js'
import SessionExpModal from '../modals/SessionExpModal.js'
import SessionWarningModal from '../modals/SessionWarningModal.js'
import { dashboardDataInstance } from '../arc-dropdown/dashboardDataInstance'
import ConsentBanner from '../consent-banner/consentBanner.js'
import * as Pendo from '../../config/pendo'
console.log(`Arc Nav Version: ${Pkg.version}`)
React.Fragment = 'x-fragment'

const runUnderCypress = process.env.CYPRESS === 'yes'
const match = isMatch.bind(null, window.location)
const storageKey = 'arc.navbar.session.messageTime'
const pollTime = runUnderCypress ? 1000 : 30000

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gaInitialized: false,
      subOpen: false,
      subNav: '',
      subNavLinks: [],
      globalTimeout: 0,
      showSessionExpModal: false,
      showSessionWarningModal: false,
      userPermissions: [],
      isBehindTrident: null,
      consentApproval: false,
      disableFetchCalls: props.disableFetchCalls, // add the prop to state if you want to use it later in your component
    }

    this.sessionTimer = null
    this.confirmSessionExp = this.confirmSessionExp.bind(this)
    this.confirmWarning = this.confirmWarning.bind(this)
    this.setSubNav = this.setSubNav.bind(this)
    this.hashChange = this.hashChange.bind(this)
    this.pageShowHandler = this.pageShowHandler.bind(this)
    this.fetchUserData = this.fetchUserData.bind(this)
    this.visibilityChangeHandler = this.visibilityChangeHandler.bind(this)
    this.handleAcceptRejectAction = this.handleAcceptRejectAction.bind(this)
  }

  fetchUserData() {
    if (this.state.disableFetchCalls) {
      return console.warn('fetchUserData disabled')
    }

    const fetchSettings = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
    }

    return fetch(`/user`, fetchSettings).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      throw new Error('failed to fetch app data')
    })
  }

  componentDidMount() {
    this.mounted = true

    this.setSubNav()
    window.addEventListener('hashchange', this.hashChange, false)

    // AH-510 - Update on pushState
    window.addEventListener('popstate', this.hashChange, false)
    const orginalPushState = window.history.pushState
    this.orginalPushState = orginalPushState
    // since by design it does not fire a pop state, we
    // are going to extend it so we know when it is called
    window.history.pushState = (o, t, u) => {
      const ops = orginalPushState.apply(history, [o, t, u])
      this.hashChange()
      return ops
    }

    const host = window && window.location && window.location.host
    const [orgAndEnv] = host ? host.split('.arcpublishing.com') : []

    let jsonData = require('./pendoOrgs.json')
    const isInvalidOrg = jsonData.orgs.includes(orgAndEnv)

    this.setSessionHandlers()
    window.addEventListener('pageshow', this.pageShowHandler, false)
    window.addEventListener(
      'visibilitychange',
      this.visibilityChangeHandler,
      false
    )

    const requests = [dashboardDataInstance]

    if (this.props.permissionKey) {
      requests.push(this.fetchUserData())
    }

    // Adding Pendo configurations and initializing
    // Removing validation for dev envs purposes
    if (this.props.enablePendo && !isInvalidOrg) {
      fetch('/user.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
          return response.json()
        })
        .then((userData) => {
          const userId = userData.id || 'NoUserId'
          let consentDate = userData.tracking
          if (consentDate) {
            this.enablingPendo(userId)
          } else {
            const localStorageTracking = window.localStorage.getItem('tg')
            if (localStorageTracking) {
              if (localStorageTracking === 'true') {
                this.enablingPendo(userId)
                consentDate = true
                this.handleAcceptRejectAction(true)
              } else if (localStorageTracking === 'false') {
                consentDate = false
                this.handleAcceptRejectAction(false)
              }
            }
          }
          if (consentDate === false || consentDate) {
            this.setState({ consentApproval: false })
          } else {
            this.setState({ consentApproval: true })
          }
        })
        .catch(() => {
          console.error('Unable to fetch Pendo User Id from user data')
          return {
            userId: 'NoUserId',
            consentDate: null,
          }
        })
    }

    Promise.all(requests).then(
      ([{ isBehindTrident }, { permissions } = {}]) => {
        const userPermissions = permissions
          ? (permissions[this.props.permissionKey] || {}).global || []
          : []

        if (this.mounted) {
          this.setState({
            userPermissions,
            isBehindTrident,
          })
        }
      }
    )
  }

  componentWillUnmount() {
    this.mounted = false
    this.clearSessionTimeoutHandlers()
    window.removeEventListener('hashchange', this.hashChange, false)
    window.removeEventListener('pageshow', this.pageShowHandler, false)
    window.removeEventListener('popstate', this.hashChange, false)
    window.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler,
      false
    )
    window.history.pushState = this.orginalPushState
  }

  wasWarningAcknowledged() {
    // get the time the last time it was displayed
    const lastTimeStamp = window.localStorage.getItem(storageKey)
    // if we have a time and it ws seen was within the last hour than
    return lastTimeStamp && Date.now() - Number(lastTimeStamp) <= 3600000
  }

  // visibilityChange and pageShow works in parallel.
  // pageShow event fired when page initially loaded or navigated back into.
  // pageShow effectively (re)start session timeout timer
  // visibilityChange triggers on tab change. The purpose of this handler is to
  // start timers & do check when user navigate back to the page (let's say after doing login on the another page after session timeout)
  // it should not call pageShowHandler every time, otherwise check expiration timer will be restarted
  visibilityChangeHandler() {
    if (document.visibilityState === 'visible' && !this.sessionTimer) {
      this.pageShowHandler()
    }
  }

  // When page is shown, restart the event handlers and check session
  pageShowHandler() {
    this.clearSessionTimeoutHandlers()
    this.setSessionHandlers()
    this.checkSession()
  }

  getGlobalTimeout() {
    const sessionInfo = Cookies.getJSON('Arc-Client-Info') || {}
    const globalTimeout = sessionInfo['global-exp']
    return globalTimeout
  }

  setSessionHandlers(timeout) {
    const intervalTime = timeout || pollTime
    if (this.getGlobalTimeout()) {
      // check every 30 seconds
      this.sessionTimer = window.setInterval(
        () => this.checkSession(),
        intervalTime
      )
    }
  }

  checkSession() {
    const { autoRefreshOnCookieExpiration = true } = this.props
    const globalTimeout = this.getGlobalTimeout()
    if (globalTimeout) {
      const now = Date.now()

      // session timeout
      const expTimeout = globalTimeout - now
      // session warning
      const isInsideWarningPeriod = expTimeout <= 3600000
      // If we are under 30 seconds, than session is going to be expired
      if (expTimeout <= 0) {
        // show expire message to user
        this.clearSessionTimeoutHandlers()
        this.setState({
          showSessionExpModal: true,
          showSessionWarningModal: false,
        })
      } else if (
        isInsideWarningPeriod &&
        !this.wasWarningAcknowledged() &&
        !this.state.showSessionWarningModal
      ) {
        // if under 60 and user did not ackknowledge
        // Show warning message to user
        this.setState({
          globalTimeout,
          showSessionWarningModal: true,
        })
      } else if (expTimeout < pollTime) {
        this.clearSessionTimeoutHandlers()
        this.setSessionHandlers(expTimeout)
      }
    } else {
      // no cookie, than kill it
      this.clearSessionTimeoutHandlers()

      if (
        this.state.isBehindTrident &&
        autoRefreshOnCookieExpiration !== false
      ) {
        // Refresh page if there is no Arc Cookie
        setTimeout(() => {
          window.location.reload(true)
        }, 1000)
        console.error(
          'ArcNav can not get access to the Arc-Client-Info cookie. Refreshing.'
        )
      }
    }
  }

  confirmSessionExp() {
    this.setState({
      showSessionExpModal: false,
    })
    // Do not open a new tab while under cypress
    if (runUnderCypress) {
      return
    }
    // Open root path in new tab when session expires which will be redriected to login page by Trident
    const newTab = window.open('/', '_blank')
    if (newTab) {
      // Force focusing on the new tab for cross-browser support
      newTab.focus()
    }
  }

  confirmWarning() {
    this.setState({
      showSessionWarningModal: false,
    })
    // Set the current time for when the message is being displayed
    window.localStorage.setItem(storageKey, new Date().getTime())
  }

  clearSessionTimeoutHandlers() {
    if (this.sessionTimer) {
      window.clearInterval(this.sessionTimer)
      this.sessionTimer = null
    }
  }

  setSubNav() {
    const { links } = this.props

    const open = (links || []).find((l) =>
      l.href || l.customEvent ? match(l) : l.sub.find(match)
    )

    this.setState(
      open && open.sub
        ? { subNav: open.name, subNavLinks: open.sub }
        : { subNav: '', subNavLinks: [] }
    )
  }

  hashChange() {
    this.setSubNav()
    this.forceUpdate()
  }

  toggleSub(l) {
    this.setState((prev) => {
      const wasPrevOpen = prev.subNav === l.name
      return {
        subNav: wasPrevOpen ? '' : l.name,
        subNavLinks: wasPrevOpen ? [] : l.sub,
      }
    })
  }

  toggleHamburger() {
    this.setState((prev) => ({
      hambugerState: !prev.hambugerState,
    }))
  }

  linkClicked(link, event) {
    if (link.customEvent) {
      const { type, detail = null } = link.customEvent

      if (!type) {
        throw new Error(
          'Custom event configuration missing required value: "type"'
        )
      }

      window.dispatchEvent(
        new window.CustomEvent(type, { detail, bubbles: true })
      )
      event.preventDefault()
    } else if (link.usePushState) {
      window.history.pushState({}, getLinkTranslation(link), link.href)
      const popEvent = document.createEvent('HTMLEvents')
      popEvent.initEvent('popstate', true, false)
      window.dispatchEvent(popEvent)
      this.forceUpdate()
      event.preventDefault()
    }
  }

  hasPermission(link) {
    return link.permission
      ? link.permission.some((permission) =>
          this.state.userPermissions.includes(permission)
        )
      : true
  }

  handleAcceptRejectAction(action) {
    this.setState({ consentApproval: false })
    fetch('/updateTrackingPrefs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ acceptTracking: action }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.json()
      })
      .then(() => {
        // Handle the response data from the POST request
        console.log('Performance cookies accepted')
        localStorage.removeItem('tg')
      })
      .catch((error) => {
        // Handle errors, e.g., network issues, or server errors
        console.warn('Error setting tracking data:', error)
        console.warn('Storing the consent response locally.')
        localStorage.setItem('tg', action)
      })
  }

  enablingPendo(userId) {
    Pendo.config()
    Pendo.initialize(userId)
    console.log('Pendo Enabled')
  }

  render() {
    const {
      appname,
      hideArcHomeNav = false,
      hideEnv = false,
      hideUserMenu = false,
      homeLink,
      isModalMenu = false,
      links,
      userLinks,
      version,
      isQuickNavOpen = false,
    } = this.props
    const {
      subNav,
      subNavLinks,
      globalTimeout,
      showSessionExpModal,
      showSessionWarningModal,
      consentApproval,
    } = this.state

    const isUserMenuHidden = hideUserMenu || isModalMenu
    const isArcNavHidden = hideArcHomeNav || isModalMenu
    const isHideEnv = hideEnv || isModalMenu

    const userLinksFiltered = (userLinks || []).filter((l) =>
      this.hasPermission(l)
    )

    const homeDetails =
      !homeLink || typeof homeLink === 'string'
        ? { href: homeLink || '/', usePushState: false }
        : homeLink

    const genSub = (sub) => {
      return sub
        .filter((l) => this.hasPermission(l))
        .map((l) => (
          <li
            key={l.name}
            className={styles.item + (match(l) ? ' ' + styles.active : '')}
            style={{ backgroundColor: '#29AE5B' }}
          >
            <a
              href={l.href}
              onClick={this.linkClicked.bind(this, l)}
              className={match(l) ? styles.active : undefined}
            >
              {getLinkTranslation(l)}
            </a>
          </li>
        ))
    }
    const subNavElements = genSub(subNavLinks)
    const pageLinks = (links || [])
      .filter((l) => this.hasPermission(l))
      .map((l) => (
        <li
          key={l.name}
          className={styles.item + (match(l) ? ' ' + styles.active : '')}
          style={{ backgroundColor: '#29AE5B' }}
        >
          {l.href || l.customEvent ? (
            <a
              href={l.href}
              target={l.target}
              onClick={this.linkClicked.bind(this, l)}
              className={match(l) ? styles.active : undefined}
            >
              {getLinkTranslation(l)}
            </a>
          ) : (
            <div>
              <div
                className={subNav === l.name ? 'open' : undefined}
                onClick={this.toggleSub.bind(this, l)}
              >
                {getLinkTranslation(l)}
              </div>
              <ul>{genSub(l.sub)}</ul>
            </div>
          )}
        </li>
      ))

    const navBarClassName =
      `${styles.navbar} ${normalize.reset}` +
      (runUnderCypress ? ` ${styles.sutCypress}` : '')

    return (
      <React.Fragment>
        <nav className={navBarClassName}>
          <ArcDropdown
            appname={appname}
            disableArcLogoHref={isModalMenu}
            isEnabled={!isArcNavHidden}
            defaultOpen={isQuickNavOpen}
            disableFetchCalls={this.state.disableFetchCalls}
          />
          <ul>
            <li className={styles.app}>
              <div>
                <a
                  onClick={this.linkClicked.bind(this, homeDetails)}
                  href={homeDetails.href}
                  data-testid="nav-app-name-header"
                >
                  {appname}
                </a>
              </div>
            </li>
          </ul>
          <label className={styles.ham} htmlFor="ham_dd">
            <img src={ham} data-testid="nav-hamburger" />
          </label>
          <input type="checkbox" id="ham_dd" />
          <ul className={styles.pageLinks}>{pageLinks}</ul>
          <div className={styles.rhs}>
            {!isHideEnv && <EnvLabel />}
            {!isUserMenuHidden && (
              <UserDropdown
                links={userLinksFiltered}
                version={version}
                clickHandler={this.linkClicked.bind(this)}
              />
            )}
          </div>
        </nav>
        {subNavElements && subNavElements.length > 0 && (
          <nav className={styles.navbar + ' ' + styles.subnav}>
            <ul>{subNavElements}</ul>
          </nav>
        )}
        {showSessionExpModal && (
          <SessionExpModal confirm={this.confirmSessionExp} />
        )}
        {showSessionWarningModal && (
          <SessionWarningModal
            globalTimeout={globalTimeout}
            confirm={this.confirmWarning}
            wasWarningAcknowledged={this.wasWarningAcknowledged}
          />
        )}
        {this.props.enablePendo && consentApproval && (
          <ConsentBanner
            handleAcceptRejectAction={this.handleAcceptRejectAction}
          />
        )}
      </React.Fragment>
    )
  }
}

Navbar.defaultProps = {
  enablePendo: false,
  customDatalayer: {},
}
export default Navbar
